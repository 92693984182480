import { lazy } from 'react';
import { RoleBasedGuard } from 'src/auth/guard';

const EnrollmentListPage = lazy(() => import('src/pages/enrollment/list'));
const EnrollmentDetailsPage = lazy(() => import('src/pages/enrollment/details'));

const guardedEnrollmentListPage = (
  <RoleBasedGuard navigateTo roles={['User.Admin']}>
    <EnrollmentListPage />
  </RoleBasedGuard>
);

const guardedEnrollmentDetailsPage = (
  <RoleBasedGuard navigateTo roles={['User.Admin']}>
    <EnrollmentDetailsPage />
  </RoleBasedGuard>
);

export const enrollmentRoutes = [
  {
    path: 'enrollment',
    children: [
      { element: guardedEnrollmentListPage, index: true },
      {
        path: ':enrollmentId',
        children: [{ element: guardedEnrollmentDetailsPage, index: true }],
      },
    ],
  },
];
