import { lazy } from 'react';
import { Outlet } from 'react-router-dom';
// layouts
import SimpleLayout from 'src/layouts/simple';
import CompactLayout from 'src/layouts/compact';

// ----------------------------------------------------------------------

const Page500 = lazy(() => import('src/pages/500'));
const Page403 = lazy(() => import('src/pages/403'));
const Page404 = lazy(() => import('src/pages/404'));
const MaintenancePage = lazy(() => import('src/pages/maintenance'));
const EnrollmentPage = lazy(() => import('src/pages/enrollment'));

// ----------------------------------------------------------------------

export const mainRoutes = [
  {
    element: (
      <SimpleLayout>
        <Outlet />
      </SimpleLayout>
    ),
    children: [
      {
        path: 'enroll',
        element: <EnrollmentPage />,
      },
    ],
  },
  {
    element: (
      <CompactLayout>
        <Outlet />
      </CompactLayout>
    ),
    children: [
      { path: 'maintenance', element: <MaintenancePage /> },
      { path: '500', element: <Page500 /> },
      { path: '404', element: <Page404 /> },
      { path: '403', element: <Page403 /> },
    ],
  },
];
