import { useMemo } from 'react';
// routes
import { paths } from 'src/routes/paths';
// locales
import { useLocales } from 'src/locales';
// components
import Iconify from 'src/components/iconify';

// ----------------------------------------------------------------------

const icon = (name) => (
  // <SvgColor src={`/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />
  // OR
  <Iconify icon={`${name}`} width={48} />
  // https://icon-sets.iconify.design/solar/
  // https://www.streamlinehq.com/icons
);

const ICONS = {
  student: icon('solar:user-bold-duotone'),
  profileReview: icon('solar:clipboard-check-bold-duotone'),
  enrollment: icon('solar:user-plus-bold-duotone'),
  vehicle: icon('solar:bus-bold-duotone'),
  part: icon('solar:accumulator-bold-duotone'),
  workOrder: icon('solar:document-add-bold-duotone'),
  purchaseOrder: icon('solar:bill-list-bold-duotone'),
  routing: icon('solar:route-bold-duotone'),
  crossboundary: icon('solar:streets-map-point-bold-duotone'),
  schoolYear: icon('solar:calendar-bold-duotone'),
  fileExport: icon('solar:export-bold-duotone'),
};

// ----------------------------------------------------------------------

export function useNavData(students) {
  const { t } = useLocales();
  const studentsArray = students?.map((student) => ({
    title: t(student.firstname),
    path: paths.student.profile(student.state_studentnumber),
  }));

  const data = useMemo(
    () => [
      // STUDENTS
      // ----------------------------------------------------------------------
      {
        roles: ['User.Admin'],
        subheader: t('students'),
        items: [
          {
            title: t('students'),
            path: paths.student.root,
            icon: ICONS.student,
            roles: ['User.Admin'],
          },
          {
            title: t('students'),
            path: '#',
            icon: ICONS.student,
            roles: ['User.User'],
            // children: studentsArray,
          },
          {
            title: t('profile reviews'),
            path: paths.profileReview.root,
            icon: ICONS.profileReview,
            roles: ['User.Admin'],
          },
          {
            title: t('enrollments'),
            path: paths.enrollment.root,
            icon: ICONS.enrollment,
            roles: ['User.Admin'],
          },
        ],
      },
      // ROUTING
      {
        roles: ['transportation.manager', 'transportation.admin'],
        subheader: t('routing'),
        items: [
          {
            title: t('routing requests'),
            path: paths.routing.root,
            icon: ICONS.routing,
          },
          {
            title: t('cross boundary requests'),
            path: paths.crossboundary.root,
            icon: ICONS.crossboundary,
          },
        ],
      },
      // STUDENT PROFILE ADMIN
      {
        roles: ['studentdata.admin'],
        subheader: t('student profile admin'),
        items: [
          // SCHOOL YEARS
          {
            title: t('school years'),
            path: paths.schoolYear.root,
            icon: ICONS.schoolYear,
          },
          // FILE EXPORTS
          {
            title: t('file exports'),
            path: paths.fileExport.root,
            icon: ICONS.fileExport,
          },
        ],
      },
      // FLEET MANAGEMENT
      // ----------------------------------------------------------------------
      {
        roles: ['fleet.mechanic', 'fleet.manager'],
        subheader: t('fleet management'),
        items: [
          // VEHICLES
          {
            title: t('vehicles'),
            path: paths.vehicle.root,
            icon: ICONS.vehicle,
          },
          // PARTS
          {
            title: t('parts'),
            path: paths.part.root,
            icon: ICONS.part,
          },
          // WORK ORDERS
          {
            title: t('work orders'),
            path: paths.workOrder.root,
            icon: ICONS.workOrder,
          },
          {
            title: t('purchase orders'),
            path: paths.purchaseOrder.root,
            icon: ICONS.purchaseOrder,
          },
        ],
      },
    ],
    [t]
  );

  return data;
}
