// ----------------------------------------------------------------------

import { en } from '@faker-js/faker';

const ROOTS = {
  AUTH: '/auth',
  DASHBOARD: '/',
  STUDENT: '/student',
  PROFILEREVIEW: '/profile-review',
  ENROLLMENT: '/enrollment',
  VEHICLE: '/vehicle',
  PART: '/part',
  WORKORDER: '/workorder',
  PURCHASE_ORDER: '/purchase-order',
  CROSS_BOUNDARY: '/crossboundary-request',
  ROUTING: '/routing-request',
  SCHOOL_YEAR: '/school-year',
  FILE_EXPORT: '/file-export',
};

// ----------------------------------------------------------------------

export const paths = {
  page403: '/403',
  page404: '/404',
  page500: '/500',
  // AUTH
  auth: {
    amplify: {
      login: `${ROOTS.AUTH}/amplify/login`,
      verify: `${ROOTS.AUTH}/amplify/verify`,
      register: `${ROOTS.AUTH}/amplify/register`,
      newPassword: `${ROOTS.AUTH}/amplify/new-password`,
      forgotPassword: `${ROOTS.AUTH}/amplify/forgot-password`,
    },
    jwt: {
      login: `/login`,
      register: `${ROOTS.AUTH}/jwt/register`,
    },
    firebase: {
      login: `${ROOTS.AUTH}/firebase/login`,
      verify: `${ROOTS.AUTH}/firebase/verify`,
      register: `${ROOTS.AUTH}/firebase/register`,
      forgotPassword: `${ROOTS.AUTH}/firebase/forgot-password`,
    },
    auth0: {
      login: `${ROOTS.AUTH}/auth0/login`,
    },
  },
  // DASHBOARD
  dashboard: ROOTS.DASHBOARD,
  // STUDENT
  student: {
    root: ROOTS.STUDENT,
    profile: (asn) => `${ROOTS.STUDENT}/${asn}`,
  },
  // PROFILE REVIEW
  profileReview: {
    root: ROOTS.PROFILEREVIEW,
    edit: (profileReviewId) => `${ROOTS.PROFILEREVIEW}/${profileReviewId}`,
    start: (profileReviewId) => `${ROOTS.PROFILEREVIEW}/${profileReviewId}/start`,
    form: (profileReviewId) => `${ROOTS.PROFILEREVIEW}/${profileReviewId}/form`,
    confirmation: (profileReviewId) => `${ROOTS.PROFILEREVIEW}/${profileReviewId}/confirmation`,
  },
  enrollment: {
    root: ROOTS.ENROLLMENT,
    edit: (enrollmentId) => `${ROOTS.ENROLLMENT}/${enrollmentId}`,
  },
  crossboundary: {
    root: ROOTS.CROSS_BOUNDARY,
  },
  routing: {
    root: ROOTS.ROUTING,
  },
  schoolYear: {
    root: ROOTS.SCHOOL_YEAR,
  },
  fileExport: {
    root: ROOTS.FILE_EXPORT,
  },

  // VEHICLE
  vehicle: {
    root: ROOTS.VEHICLE,
    new: `${ROOTS.VEHICLE}/new`,
    edit: (vehicleId) => `${ROOTS.VEHICLE}/${vehicleId}/edit`,
    details: (vehicleId) => `${ROOTS.VEHICLE}/${vehicleId}`,
  },
  // PART
  part: {
    root: ROOTS.PART,
    new: `${ROOTS.PART}/new`,
    edit: (partId) => `${ROOTS.PART}/${partId}/edit`,
  },
  // WORK ORDER
  workOrder: {
    root: ROOTS.WORKORDER,
    new: `${ROOTS.WORKORDER}/new`,
    edit: (workOrderId) => `${ROOTS.WORKORDER}/${workOrderId}/edit`,
  },
  // PURCHASE ORDER
  purchaseOrder: {
    root: ROOTS.PURCHASE_ORDER,
    new: `${ROOTS.PURCHASE_ORDER}/new`,
    edit: (purchaseOrderId) => `${ROOTS.PURCHASE_ORDER}/${purchaseOrderId}/edit`,
  },
};
