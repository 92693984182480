export const STATUS_OPTIONS = [
  {
    value: 'all',
    label: 'All',
    viewOnly: true,
  },
  {
    value: 'open',
    label: 'Not Started',
  },
  {
    value: 'active',
    label: 'In Progress',
  },
  {
    value: 'pending',
    label: 'Pending Review',
  },
  {
    value: 'complete',
    label: 'Complete',
  },
];

export const SECTION23_OPTIONS = {
  B: 'Ineligible',
  Y: 'Eligible',
  C: 'Unknown',
};

export const RELATIONSHIP_OPTIONS = [
  '',
  'Mother',
  'Father',
  'Grandparent',
  'Grandmother',
  'Grandfather',
  'Aunt',
  'Uncle',
  'Babysitter',
  'Brother',
  'Cousin',
  'Emergency',
  'Friend',
  'Foster Father',
  'Foster Mother',
  'Grandparents',
  'Guardian',
  'Neighbor',
  'Not Set',
  'Other',
  'Sister',
  'Social Worker',
  'Sponsor',
  'Step Father',
  'Step Mother',
];
